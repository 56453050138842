// Adapted from https://github.com/aralroca/next-translate/issues/721#issuecomment-966859046
// and https://github.com/aralroca/next-translate/issues/721#issuecomment-1052075661,
// simplifed for non-nested translations in json files

import {
  DEFAULT_LANGUAGE_CODE,
  FieldValue,
  isLanguageCode,
  LocalizedField,
  LanguageCode,
} from "@libry-content/localization";
import type { I18n } from "next-translate";
// eslint-disable-next-line no-restricted-imports -- we restrict other files to import from this one
import useNextTranslation from "next-translate/useTranslation";
import { useCallback } from "react";
import { translateSanity } from "@libry-content/localization";
import { isJestTest } from "@libry-content/common";

export type TranslateSanityContent = <T = FieldValue>(localizedObject?: LocalizedField<T>) => T | undefined;

export interface Translate extends Omit<I18n, "lang"> {
  ts: TranslateSanityContent;
  lang: LanguageCode;
}

export type TranslateMicroText = Translate["t"];

export const useTranslation = ({ silent }: { silent?: true } = {}): Translate => {
  const { lang: nextLang, ...rest } = useNextTranslation();

  const lang = isLanguageCode(nextLang) ? nextLang : DEFAULT_LANGUAGE_CODE;

  if (!silent && nextLang !== lang && nextLang !== "default" && !isJestTest) {
    console.error("Attempted to translate to lang", nextLang);
  }

  const ts: Translate["ts"] = useCallback((localizedObject) => translateSanity(localizedObject, lang), [lang]);

  return { ...rest, lang, ts };
};
