import { useRouter } from "next/router";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { usePrevious } from "react-use";
import PreviewBanner from "./PreviewBanner";

type State = {
  /**
   * undefined means it's pending, waiting for queryparams to be available.
   *
   * Some functionality, etc metrics, should not be loaded before it's clear wether preview is on or off.
   * We don't want to log metrics when the page is displayed in previewmode i sanity studio.
   * */
  isOn?: boolean;
  toggle: () => void;
  setPreviewMode: (isOn: boolean) => void;
};

const initState: State = {
  isOn: undefined,
  toggle: () => null,
  setPreviewMode: () => null,
};

const Context = createContext<State>(initState);

export const usePreviewContext = () => useContext(Context);
interface Props {
  children: ReactNode;
}

export const PreviewContextProvider = (props: Props) => {
  const router = useRouter();
  const [previewMode, setPreviewMode] = useState(initState.isOn);

  const prevRouter = usePrevious(router);
  useEffect(() => {
    // I det router.isReady endres fra false til true blir query-params også tilgjengelig fra router og vi kan sette previewMode false/true basert på om preview er satt i url vha "?preview=true"
    if (!prevRouter?.isReady && router.isReady) {
      const previewInQueryParams = router.query.preview === "true";
      setPreviewMode(previewInQueryParams);
    }
  }, [prevRouter, router]);

  return (
    <Context.Provider
      value={{
        isOn: previewMode,
        toggle: () => setPreviewMode((s) => !s),
        setPreviewMode: (isOn) => setPreviewMode(isOn),
      }}
    >
      <PreviewBanner />
      {props.children}
    </Context.Provider>
  );
};
