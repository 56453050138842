const Sentry = require("@sentry/nextjs");

// Cannot import these from common-package as this gives deploy errors
const dataset = process.env.SANITY_STUDIO_API_DATASET;
const PRODUCTION_DATASET = "production";
const isProduction = dataset === PRODUCTION_DATASET;
const isE2e = dataset?.trim().startsWith("e2e-");

module.exports = {
  defaultNS: "common",
  pages: {
    // Since our volume of translations is not that high, and to simplify Poeditor setup, we use a single namespace
    "*": ["common"],
  },
  // Use "default" to redirect requests with no locale: https://nextjs.org/docs/advanced-features/i18n-routing#prefixing-the-default-locale
  defaultLocale: "default",
  locales: ["default", "nb", "nn"],
  // Use the ICU interpolation format rather than the default "{{}}" for interop with eslint-plugin-i18n-json
  interpolation: { prefix: "{", suffix: "}" },
  // We disallow nested translations with `keySeparator: false`, since we use natural language keys – this also simplifies some ts types
  keySeparator: false,
  // Use namespace separator "::" rather than the default ":", since the latter will be in use in natural language keys
  nsSeparator: "::",
  // With `allowEmptyStrings: false`, missing translations will fall back to the key rather than displaying empty
  allowEmptyStrings: false,
  logger: ({ i18nKey, namespace }) => {
    const isDevRoute = typeof window !== "undefined" && window.location.pathname.match(/\/dev(?:\/.*)?$/);
    const errorMessage = `Missing translation: "${namespace === "common" ? "" : `${namespace}:`}${i18nKey}"`;

    // We want to use console.log since it's easier to read and we don't need the stack trace.
    // In order to make it visible like an error we use some formatting.
    console.log(`%c ❗️ ${errorMessage} `, "background: #fbe4e4; color: red;");

    if (isProduction && !isDevRoute) {
      Sentry.captureException(errorMessage);
    }

    if (isE2e) {
      throw new Error(errorMessage);
    }
  },
};
