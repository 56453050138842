type DefaultApiError = { error: string };

export type ApiResponse<Data = {}, Error = DefaultApiError> = Data | Error;

export function isApiError(response?: ApiResponse): response is DefaultApiError {
  return (response as DefaultApiError)?.error !== undefined;
}

export type SanityValidationStatus = {
  message: string;
  type: "success" | "warning" | "error";
  field?: "type" | "database" | "mikromarcUnit";
};

export type SanityValidationApiResponse = ApiResponse<SanityValidationStatus>;

export type BaseAutocompleteResult = {
  id: string;
};

export type PublicationResult = BaseAutocompleteResult & {
  isbn?: string;
  mediaType?: string;
  edition?: string;
  publicationYear?: string;
  title?: string;
  author?: string;
  language?: string;
  url?: string;
  score?: number;
  summary?: string;
  subject?: string;
  genre?: string;
};

export type AutocompleteResponse<T> = {
  results: T[];
  source?: string;
  error?: string;
};

export type FindPublicationsApiResponse = ApiResponse<AutocompleteResponse<PublicationResult>>;

// https://plausible.io/docs/stats-api#metrics
export type SitePlausibleData = {
  results: {
    visitors: {
      value: number;
    };
    visits: {
      value: number;
    };
    pageviews: {
      value: number;
    };
  };
};

export type SiteMetricsResponse = {
  currentYear: SitePlausibleData["results"];
  lastYear: SitePlausibleData["results"];
};

// https://plausible.io/docs/stats-api#metrics
export type PagePlausibleData = {
  results: {
    visitors: {
      value: number;
    };
    visits: {
      value: number;
    };
    pageviews: {
      value: number;
    };
  };
};

export type PageStatsResponse = ApiResponse<PagePlausibleData["results"]>;
