import { sanityConfig } from "./sanityConfig";
import createImageUrlBuilder from "@sanity/image-url";
import { SanityImageAsset, SanityImageCrop, SanityImageHotspot, SanityReference } from "@libry-content/types";

interface SanityImageObject {
  asset: SanityReference<SanityImageAsset> | SanityImageAsset;
  crop?: SanityImageCrop;
  hotspot?: SanityImageHotspot;
}

export const imageUrlBuilder = (source?: SanityImageObject) => {
  // Sånn som vi har satt opp bilder i sanity nå kan bilde-objektet eksistere selv om det ikke har asset. Det får createImageUrlBuilder til å krasje
  if (!source?.asset) return undefined;
  try {
    return createImageUrlBuilder(sanityConfig).image(source);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};
