export const isJestTest = process.env.NODE_ENV === "test";

export const localhostUrl = new URL("http://localhost:3000");
export const isLocalhost = (hostname: string) => hostname.match(localhostUrl.hostname);

// Note: For the NextJS compiler to find environment variables, they cannot be accessed
// using object destructuring or any other way than process.env.NAME.
export const platformUrl = process.env.SANITY_STUDIO_PLATFORM_URL
  ? new URL(process.env.SANITY_STUDIO_PLATFORM_URL)
  : // Fallback to a valid, but weird invalid URL that is easy to search for.
    // If you get this, it's most likely because you forgot to use Doppler with
    // a script that requires environment variables.
    new URL("http://SANITY_STUDIO_PLATFORM_URL-is-not-defined.invalid");

export const getSubdomainUrl = (subdomain: string | undefined): string =>
  subdomain ? `${platformUrl.protocol}//${subdomain}.${platformUrl.host}` : platformUrl.toString();

export const PRODUCTION_DATASET = "production";

export const SANDBOX_DATASET = "test";
