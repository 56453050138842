import { createContext, ReactNode, useContext } from "react";
import { CommonData } from "../../utils/fetchWithCommonDataAndCurrentSiteFilter";

const Context = createContext<CommonData>({});

export const useCommonData = () => useContext(Context);

interface Props {
  commonData: CommonData;
  children: ReactNode;
}

export const CommonDataProvider = (props: Props) => (
  <Context.Provider value={props.commonData}>{props.children}</Context.Provider>
);
