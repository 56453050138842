import Head from "next/head";

/**
 * Fordi vi har en del `fallback: 'blocking' | true` i getStaticProps må vi sørge for at
 * feil-sidene våre ikke blir indeksert. Disse sidene får fortsatt status 200 fra server:
 * https://github.com/vercel/next.js/discussions/10960
 */
export const ErrorPageHead = () => (
  <Head>
    <meta name="robots" content="noindex" />
  </Head>
);
