import { isDevelopment } from "@biblioteksentralen/js-utils";
import { getEnabledFeatureToggles } from "@libry-content/common";
import { useMount } from "react-use";

const libryContentVersion =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA?.slice(0, 7) ?? (isDevelopment() ? "development" : "unknown");

function logVercelInfo() {
  console.info("%c🌐 Libry Content version: ", "color: blue", libryContentVersion);
}

const logEnabledFeatureToggles = () => {
  const enabledFeatureToggles = getEnabledFeatureToggles();
  enabledFeatureToggles.length && console.log("🎛️ Enabled feature toggles: ", enabledFeatureToggles);
};

export const useLogDevInfo = () => {
  useMount(() => {
    logVercelInfo();
    logEnabledFeatureToggles();
  });
};
