const notRepeated = `(!repeated || !defined(repeated))`;
const notAllDay = `(!allDay || !defined(allDay))`;

export const eventOnDateOrLater = (dateVariable: string) => `(
  (allDay && ${notRepeated} && endDate >= ${dateVariable}) ||
  (${notAllDay} && ${notRepeated} && startDate >= ${dateVariable}) ||
  (repeated && count(repeatedDates[@.date >= ${dateVariable}]) > 0)
)`;

export const eventBeforeDate = (dateVariable: string) => `(
  (allDay && ${notRepeated} && endDate < ${dateVariable}) ||
  (${notAllDay} && ${notRepeated} && startDate < ${dateVariable}) ||
  (repeated && count(repeatedDates[@.date < ${dateVariable}]) > 0)
)`;

export const filters = Object.freeze({
  isDraft: `_id in path('drafts.**')`,
  isNotDraft: `!(_id in path('drafts.**'))`,
  siteRef: `(site._ref == $siteId || !defined(site))`,
  eventTodayOrLater: eventOnDateOrLater("$today"),
  eventNotFinished: `(
    ${notRepeated} && (
      (allDay && endDate >= $today) ||
      (${notAllDay} && (startDate > $today || startDate == $today && eventEnd > $timeNow))
    ) ||
    (repeated &&
      count(repeatedDates[
        @.date > $today ||
        @.date == $today && (coalesce(@.customization.eventEnd, ^.eventEnd) > $timeNow)
      ]) > 0)
  )`,
  eventBeforeToday: eventBeforeDate("$today"),
  eventFinished: `(
    (allDay && ${notRepeated} && endDate < $today) ||
    (${notAllDay} && ${notRepeated} && startDate < $today) ||
    (repeated && count(repeatedDates[@.date >= $today]) == 0)
  )`,
  eventLacksDates: `(
    (allDay && ${notRepeated} && !defined(endDate)) ||
    (${notAllDay} && ${notRepeated} && !defined(startDate)) ||
    (repeated && count(repeatedDates) == 0)
  )`,
});
