import { LanguageCode } from "@libry-content/localization";

const localizedNumbers: Record<LanguageCode, Record<number, string>> = {
  nb: {
    1: "en",
    2: "to",
    3: "tre",
    4: "fire",
    5: "fem",
    6: "seks",
    7: "sju",
    8: "åtte",
    9: "ni",
    10: "ti",
  },
  nn: {
    1: "ein",
    2: "to",
    3: "tre",
    4: "fire",
    5: "fem",
    6: "seks",
    7: "sju",
    8: "åtte",
    9: "ni",
    10: "ti",
  },
};

export const numberToWord = (languageCode: LanguageCode, num: number): string =>
  localizedNumbers[languageCode][num] ?? num.toString();
